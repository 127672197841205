import ModalMixin from '@/mixins/modal';
import { makeComma } from '@/utils/filters';
export default {
  mixins: [ModalMixin],
  methods: {
    makeCommaString(e) {
      let ret = String(Number(String(e.target.value).replace(/,/gi, '')));
      e.target.value = makeComma(ret);
    },

    mx_makeComma(value) {
      if (value == undefined) return '';
      if (value.length == 0) {
        return '0';
      }
      let rightDot = '';
      value = String(value).replace(/,/gi, '');
      if (value.includes('.') && value.split('.')[1].length > 0) {
        rightDot = '.' + value.split('.')[1];
        value = value.split('.')[0];
      } else {
        value = Number(String(value).replace(/,/gi, ''));
        if (value == 0) {
          return '0';
        }
      }
      return String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ',') + rightDot;
    },

    makeCost(e, target) {
      try {
        let value = null;
        if (e.data == null) {
          value = e.target.value.replace(/,/gi, '');
        } else {
          value = e.target.value.replace(/,/gi, '');
          // value = String(target.cost).replace(/,/gi, '') + e.data;
        }
        if (value.length == 0) {
          target.cost = '0';
          return;
        }
        value = Number(value);
        if (value == 0) {
          target.cost = '0';
        }
        target.cost = String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      } catch (e) {
        console.log(e);
      }
    },
    makeQuantity(e, target) {
      try {
        let value = null;
        if (e.data == null) {
          value = e.target.value.replace(/,/gi, '');
        } else {
          value = e.target.value.replace(/,/gi, '');
          // value = String(target.cost).replace(/,/gi, '') + e.data;
        }
        if (value.length == 0) {
          target.quantity = '0';
          return;
        }
        value = Number(value);
        if (value == 0) {
          target.quantity = '0';
        }
        target.quantity = String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      } catch (e) {
        console.log(e);
      }
    },

    onlyNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    onlyNumberDot(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode == 46 &&
        (evt.target.value.includes('.') || evt.target.value.length == 0)
      ) {
        evt.preventDefault();
      }
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode != 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    calSupplyTax(vat, tax, num, cos) {
      try {
        if (vat == undefined || tax == undefined) {
          this.openOneButtonModal(
            '금액 계산 오류',
            '선택한 거래처의 부가세 구분값 또는 선택한 품목의 과세여부 값이 정의되지 않았습니다.',
          );
          return { supply: 0, tax: 0, total: 0 };
        }
        const quantity = Number(String(num).replace(/,/gi, ''));
        const cost = Number(String(cos).replace(/,/gi, ''));
        if (tax == true && vat != 3) {
          if (vat == 1) {
            const supply = Math.round(quantity * cost);
            return {
              supply: supply,
              tax: Math.floor(supply * 0.1),
              total: supply + Math.floor(supply * 0.1),
            };
          } else if (vat == 2) {
            const total = Math.round(quantity * cost);
            return {
              supply: Math.round(total / 1.1),
              tax: total - Math.round(total / 1.1),
              total: total,
            };
          } else {
            this.openOneButtonModal(
              '금액 계산 오류',
              '선택한 거래처의 부가세 구분값 또는 선택한 품목의 과세여부 값이 정의되지 않았습니다.',
            );
            return { supply: 0, tax: 0, total: 0 };
          }
        } else if (tax == false || vat == 3) {
          return {
            supply: Math.round(quantity * cost),
            tax: 0,
            total: Math.round(quantity * cost),
          };
        } else {
          this.openOneButtonModal(
            '금액 계산 오류',
            '선택한 거래처의 부가세 구분값 또는 선택한 품목의 과세여부 값이 정의되지 않았습니다.',
          );
          return { supply: 0, tax: 0, total: 0 };
        }
      } catch (e) {
        console.log(e);
      }
    },
    calTotalPrice(targetArray, vat, isUseNewTaxId) {
      try {
        let non_tax_total_cost = 0;
        let tax_total_cost = 0;
        let total_cost = 0;

        let sales_cost = 0;
        targetArray.forEach(el => {
          if (el.cost == undefined || el.quantity == undefined) return null;
          if (isUseNewTaxId) {
            if (this.getMaterialInfo(el.material_id).tax == 1) {
              tax_total_cost += Number(
                this.calSupplyTax(
                  vat,
                  this.getMaterialInfo(el.material_id).tax,
                  this.$makeNumber(el.quantity),
                  this.$makeNumber(el.cost),
                ).total,
              );
            } else {
              non_tax_total_cost += Number(
                this.calSupplyTax(
                  vat,
                  this.getMaterialInfo(el.material_id).tax,
                  this.$makeNumber(el.quantity),
                  this.$makeNumber(el.cost),
                ).total,
              );
            }
          } else {
            if (el.tax_id == 1) {
              tax_total_cost += Number(
                this.calSupplyTax(
                  vat,
                  el.tax_id,
                  this.$makeNumber(el.quantity),
                  this.$makeNumber(el.cost),
                ).total,
              );
            } else {
              non_tax_total_cost += Number(
                this.calSupplyTax(
                  vat,
                  el.tax_id,
                  this.$makeNumber(el.quantity),
                  this.$makeNumber(el.cost),
                ).total,
              );
            }
          }
        });
        total_cost = non_tax_total_cost + tax_total_cost;
        sales_cost = total_cost;

        return {
          non_tax_total_cost: makeComma(non_tax_total_cost),
          tax_total_cost: makeComma(tax_total_cost),
          total_cost: makeComma(total_cost),
          sales_cost: makeComma(sales_cost),
        };
      } catch (e) {
        console.log(e);
      }
    },
  },
};
